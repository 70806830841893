import React, { useEffect, useState } from "react";
import { Loader } from "../component/loader.js";
import { NavBar } from "../component/NavBar.js";
import { Container } from "react-bootstrap";
import { FreshWorks } from "../component/FreshWorks/FreshWorks.js";
import { Helmet } from "react-helmet-async";
import { Footer } from "../component/Footer";
import ReactGA from "react-ga4";

export const FreshWorksPage = () => {
  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>
              Freshworks - Customer Engagement Software Company | Techsa
              Services Pvt. Ltd.
            </title>
            <meta
              name="description"
              content="Freshworks provides tools for improved customer communication, collaboration, automation. The user-friendly platform aims to enhance customer experience."
            />
            <link href="/FreshWorks" />
            <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <Container>
            <div className="freshworks">
              <FreshWorks />
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};
