import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../../assets/img/SW_Logo.png";
import projImg2 from "../../assets/img/136048.png";
import projImg3 from "../../assets/Tenable.png";
import projImg4 from "../../assets/img/headerLogoLight.webp";
import projImg5 from "../../assets/Armis.png";
import Aos from "aos";
import "aos/dist/aos.css";

export const Products = () => {
  const projects = [
    {
      title: "SOLARWINDS",
      imgUrl: projImg1,
      link: "/SolarWinds",
    },
    {
      title: "BIGFIX",
      imgUrl: projImg2,
      link: "/bigfix",
    },
    {
      title: "TENABLE",
      imgUrl: projImg3,
      link: "/Tenable",
    },
    {
      title: "ARMIS",
      imgUrl: projImg5,
      link: "/Armis",
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="project" id="Products">
      <Container>
      <Row>
          <Col>
            <div
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-duration="600"
            >
              <h2>Technology Partners</h2>
              <p>
                Discover our foremost technology partners, trailblazers in
                innovation. These strategic partners propel our ability to
                deliver cutting-edge solutions, ensuring our clients access the
                pinnacle of technological advancements. Together, we redefine
                boundaries and drive transformative change in the digital realm.
              </p>
            </div>
            <Row className="justify-content-center align-items-center">
              {projects.map((project, index) => {
                return <ProjectCard key={index} {...project} />;
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
