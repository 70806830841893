import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export const Workspace = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="summary-module-container">
      <div
        className="summary"
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
          {" "}
          BigFix Workspace
        </h2>
        <p style={{ textAlign: "center" }}>
          BigFix Workspace is a digital workspace management solution that
          unites tools, teams, and processes to fix laptop and mobile problems
          regardless of employee location, connectivity, or operating system,
          using the biggest set of IT automation and vulnerability remediation
          risk reduction technologies.
        </p>
      </div>

      <div
        className="module"
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
          {" "}
          BigFix Inventory
        </h2>
        <p style={{ textAlign: "center" }}>
          BigFix Inventory reduce the time required to conduct a
          comprehensive software asset inventory for license reconciliation or
          compliance purposes. Provide valuable insight into what the
          organization owns and what it has installed but does not own along
          with how often the software is being used.
        </p>
      </div>
    </div>
  );
};
