import React, { useState } from 'react';
import { Summary } from './summary';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { ContactModal } from '../ContactModal';
import { Accordion } from './accordion';

export const Solarwinds = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  const handleRequestDemo = () => {
    setShowContactModal(true);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };
  return (
    <>
     <div>
      <h1 style={{ textAlign: 'center',marginBottom:"60px" }}>SOLARWINDS</h1>
      <Summary />
      <h2 style={{ textAlign: 'center', margin:"140px 0 20px 0" }}>Feature solution</h2>
      <Accordion />

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button className="custom-button" onClick={handleRequestDemo}>
          <span>
          Request Details <FaLongArrowAltRight />
          </span>
        </button>
      </div>

      <ContactModal style={{zIndex:"100090"}} showModal={showContactModal} onClose={handleCloseContactModal} />
      </div>
    </>
  );
};
