import React from "react";
import img1 from "../../assets/Armis.png";

export const Summary = () => {
  return (
    <div className="arm">
      <div>
        <img src={img1} alt="" />
      </div>
      <div>
        <p>
          Armis is a cybersecurity platform specializing in IoT security. It
          discovers, analyzes and protects connected network devices, minimizing risks
          associated with unmanaged or vulnerable devices. Armis provides
          real-time visibility and threat detection, securing enterprise
          networks from potential cyber threats arising from the proliferation
          of IoT devices in modern business environments.
        </p>
      </div>
    </div>
  );
};
