import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export const Patch = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="summary-module-container">
      <div
        className="summary"
        data-aos="fade-right"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
          BigFix Patch Management
        </h2>
        <p style={{ textAlign: "center" }}>
          BigFix Patch Management streamlines and automates the patching process
          across diverse endpoints, ensuring security and compliance. It
          provides real-time visibility, automated patching, and reporting,
          reducing vulnerabilities.
        </p>
      </div>

      <div
        className="module"
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
          {" "}
          BigFix LifeCycle
        </h2>
        <p style={{ textAlign: "center" }}>
          HCL BigFix Lifecycle helps find and fix problems in minutes across all
          endpoints. Discover, secure and manage hundreds of thousands of
          endpoints on more than 90 different OS versions within hours or
          minutes.
        </p>
      </div>
    </div>
  );
};
