import React, { useState } from "react";
import ImageWithText from "./ImageWithText";

export const Summary = () => {
  const [displayText, setDisplayText] = useState("");
 

  const handleImageClick = (clickedAreas) => {
    let newDisplayText = "";
    if (clickedAreas && clickedAreas.includes) {
      if (clickedAreas.includes("Database")) {
        newDisplayText = (
          <>
           <p>
            SolarWinds empowers businesses by streamlining the monitoring, analysis, diagnosis, 
            and optimization of critical database performance for business applications. Unifying 
            on-premises and cloud database visibility, it provides streamlined monitoring, mapping, data
             lineage, integration, and tuning across vendors. This comprehensive solution ensures holistic
              management, boosting data operations efficiency. With a dual focus on on-premises and cloud 
              environments, SolarWinds offers a refined approach to database monitoring and optimization,
               sustaining peak performance and reliability in diverse ecosystems.
               </p>
            {/* <Link to="/Database">
              <Button style={{ marginTop: "25px" }}>Learn more</Button>
            </Link> */}
          </>
        );
      } else if (clickedAreas.includes("Network")) {
        newDisplayText = (
          <p>
   
              Network management tools, from configuration and traffic
              intelligence to performance monitoring and topology mapping, to
              readily see, understand, and resolve issues. An integrated,
              multi-vendor approach that’s easy to use, extend, and scale to
              keep distributed networks optimized.
           

            {/* <Button style={{ marginTop: "15px" }}>Learn more</Button> */}
          </p>
        );
      } else if (clickedAreas.includes("Infrastructure")) {
        newDisplayText = (
          <p>
           SolarWinds infrastructure solutions help organizations assure the health and configuration of physical and virtual servers, containers, private cloud, storage, and network infrastructure across hybrid IT environments.

            {/* <Button style={{ marginTop: "15px" }}>Learn more</Button> */}
          </p>
        );
      } else if (clickedAreas.includes("Application")) {
        newDisplayText = (
          <p>
         
            SolarWinds excels in application monitoring, offering real-time performance insights, 
            customizable alerts, and automated dependency mapping. Emphasizing user experience tracking, 
            log analysis, and capacity planning, it ensures optimal application performance. The platform's
            open architecture facilitates seamless integration, empowering organizations to tailor their monitoring 
            approach, proactively identify issues, and swiftly resolve them for uninterrupted application excellence.
          

            {/* <Button style={{ marginTop: "15px" }}>Learn more</Button> */}
          </p>
        );
      } else if (clickedAreas.includes("Cloud")) {
        newDisplayText = (
          <p>
           SolarWinds Server & Application Monitor (SAM) is built to provide end-to-end visibility into business-critical applications, regardless of whether they’re running on private, public, or hybrid cloud environments.

            {/* <Button style={{ marginTop: "15px" }}>Learn more</Button> */}
          </p>
        );
      } else if (clickedAreas.includes("Solarwinds")) {
        newDisplayText = (
          <p>
       
              SolarWinds stands out as the sole unified platform that seamlessly
              combines monitoring, observability, and service management. It
              empowers users with comprehensive insight into their IT
              infrastructure, enabling proactive identification of bottlenecks
              across the entire system.
        
          </p>
        );
      } else if (clickedAreas.includes("Automate")) {
        newDisplayText = (
          <>
           SolarWinds Network Configuration Manager (NCM) leverages network automation to make bulk configuration changes to wired and wireless devices. This helps you save time and reduces errors associated with manual updates.
          </>
        );
      } else if (clickedAreas.includes("Observe")) {
        newDisplayText = (
          <p>
        
              The SolarWinds Platform is the industry’s only unified monitoring,
              observability, and service management platform. It’s the
              foundation for a new generation of SolarWinds observability
              solutions and provides the architecture on how we solve
              observability challenges for our customers.
           
          </p>
        );
      } else if (clickedAreas.includes("Remediate")) {
        newDisplayText = (
          <>
           SolarWinds remediation efforts involve thorough investigation, patching vulnerable systems, and implementing enhanced security measures
          </>
        );
      } else if (clickedAreas.includes("Visualize")) {
        newDisplayText = (
          <p>
           SolarWinds network visualization tools provide a unified view of network connections, 
              applications, dependency relationships, topology, and ADM information. With these network map 
              visualizations, users can see the physical and logical relationships between routers, switches,
              servers, interfaces, volumes, and groups to quickly isolate and identify critical health and
              performance issues. 
          </p>
        );
      }
    }

    setDisplayText(newDisplayText);
  };

  return (
    <div className="solar">
      <div>
        <ImageWithText onClick={handleImageClick} />
      </div>
      <div>
        <p>
          {displayText || (
            <p>
              SolarWinds stands out as the sole unified platform that seamlessly
              combines monitoring, observability, and service management. It
              empowers users with comprehensive insight into their hybrid IT
              infrastructure, enabling proactive identification of bottlenecks
              across the entire system.
              
            </p>
          )}
        </p>
      </div>
    </div>
  );
};
