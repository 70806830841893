import React, { useEffect, useState } from "react";
import { Loader } from "../component/loader.js";
import { NavBar } from "../component/NavBar.js";
import { Container } from "react-bootstrap";
import { Armis } from "../component/Armis/Armis.js";
import { Helmet } from "react-helmet-async";
import { Footer } from "../component/Footer";
import ReactGA from "react-ga4";

export const ArmisPage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>
              Armis - Cybersecurity Software for IoT Devices | Techsa Services
              Pvt. Ltd.
            </title>
            <meta
              name="description"
              content="Armis provides real-time visibility and threat detection, securing enterprise networks from potential cyber threats arising from the proliferation of IoT devices."
            />
            <link href="/Armis" />
            <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <Container>
            <div className="armis">
              <Armis />
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};
