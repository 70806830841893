import React from "react";
import img1 from "../../assets/Tenable.png";

export const Summary = () => {
  return (
    <div className="ten">
     
      <div>
        <img src={img1} alt="" />
      </div>
      <div>
        <p>
          Tenable provides cybersecurity solutions that assess, manage, and
          enhance an organization's cybersecurity posture. Its products offer
          vulnerability management, continuous monitoring, and risk assessment
          to proactively identify and mitigate cyber threats. Tenable helps
          organizations maintain a strong security foundation by ensuring the
          visibility and protection of their digital assets against evolving
          cyber risks.
        </p>
      </div>
    </div>
  );
};
