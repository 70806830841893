import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Module } from "../bigfix/Module";
import security from "../../assets/SW/security.png";
import monitor from "../../assets/SW/monitor.png";
import app from "../../assets/SW/watch.png";
import infra from "../../assets/SW/syslog.png"
import db from "../../assets/SW/DB.png";
import ip from "../../assets/SW/UDT.png";
import network from "../../assets/SW/network.png";
import nta from "../../assets/SW/NTA.png";
import analyz from "../../assets/SW/Analyz.png";
import arm from "../../assets/SW/Arm.png";
import web from "../../assets/SW/web.png";

const modules = [
  {
    title: " Infrastructure Monitoring",
    image: infra,
    description: `
    Solarwinds infrastructure monitoring a vendor-agnostic solution that can monitor across your
     IT infrastructure, whether it be on-premises, in the cloud, or a combination.
   
    `,
    description1: `
    SolarWinds infrastructure solutions help organizations assure the health and configuration of
    physical and virtual servers, containers, private cloud, storage, and network infrastructure across 
    hybrid IT environments.
    `,
  },
  {
    image: nta,
    title: "Traffic Insights",
    description: `
    SolarWinds NetFlow Traffic Analyzer (NTA) allows you to capture data from continuous streams of network traffic, and convert those raw numbers into easy-to-interpret charts and tables that 
    quantify exactly how the corporate network is being used, by whom, and for what purpose. 
`,
    description1: `
    With SolarWinds NTA, it’s easierthan ever to collect and analyze Cisco® NBAR2 and NetFlow, Juniper® 
    J-Flow™, IPFIX, sFlow®, Huawei NetStream™, and other flow data.
`,
  },
  {
    image: network,
    title: "Network Configuration Monitoring",
    description: `
    SolarWinds Network Configuration Manager (NCM) saves time and improves network 
    reliability and security by managing configurations, changes, and 
    compliance for routers, switches, and other network devices from Cisco®, Juniper®, HP, Dell®
    , Brocade®, F5®, Aruba®, Rukus®, and more.
    `,
  }
  ,
  {
    image: ip,
    title: "IP Address Manager",
    description: `
    SolarWinds
 IP Address 
Manager (IPAM) can provide you with 
centralized IP address management, 
as it works hand in hand with unified 
DHCP and DNS administration and 
helps teams find and configure 
available addresses on DHCP and 
DNS systems.
`,
  },
  {
    image: analyz,
    title: "Log Intelligence",
    description: `
    SolarWinds simplifies log management by delivering a holistic 
    multi-source log observability solution designed to support almost every
     log type as well as OpenTelemetry and third-party integrations.
    `,
  },
  {
    image: security,
    title: "Security Event Manager",
    description: `
    SolarWinds Security Event Manager (SEM) is an affordable and efficient threat detection, automated incident analysis and response, and compliance reporting for IT infrastructure. 
    SolarWinds SEM combines log management, threat detection, normalization and correlation, forwarding, reporting, file integrity monitoring, user activity monitoring, USB detection 
    and prevention, threat intelligence, and active response in a virtual appliance that’s easy 
    to deploy, manage, and use. SolarWinds SEM is designed to provide the functionality you 
    need without the complexity and cost of most other enterprise SIEM solutions.
    `,
  }
];

const modules1 = [
  {
    image: app,
    title: "Application Monitoring",
    description: `
    SolarWinds takes the complexity out of application performance monitoring by 
    providing an integrated, full-stack observability solution built to connect data from web applications,
    their services, cloud and hybrid infrastructures (including Kubernetes, AWS, and Azure), databases, and
    networks. This fully integrated application performance monitoring solution collects metrics, logs,
    traces, and user experience data to analyze and provide holistic visibility.
    `,
  },
  {
    image: db,
    title: " Database Monitoring",
    description: `
    SolarWinds databse monitoring solutions offers a unified approach to cross-platform database performance
    monitoring, with intuitive configuration for multiple servers from a wide variety of vendors whether
    on-premises or in the cloud.
  `,
  },
  {
    image: nta,
    title: "Storage",
    description: `
    Storage resource management refers to the process of maximizing efficiency of storage capacity, allocation,
     usage, and forecasting at all levels of your storage environment, including raw capacity, RAIDed, LUN, 
     datastore, and file system. 
  `,
    description1: `
  Storage resource management solutions can help support these processes by centralizing an 
    organization’s storage data in a single, easy-to-access platform to help admins analyze data across
     devices from multiple vendors.
  `,
  },
  {
    image: arm,
    title: "Access Rights Manager",
    description: `
    Compliance requirements driven by GDPR, PCI, HIPAA, and other mandates require detailed user access
     monitoring, particularly for users who have access to critical and sensitive data. SolarWinds Access 
     Rights Manager (ARM) is designed to deliver customized Active Directory (AD) and Azure AD reports—showing
      who has access to what, and when they accessed this data.
    `,
  },
  {
    image: monitor,
    title: "Server Configuration Monitoring",
    description: `
    SolarWinds Server Configuration Monitor (SCM) is designed to quickly reveal when server, application, or
    database configurations change, who’s changing them, what changed, and performance impact—helping you have
    the necessary visibility to troubleshoot faster, improve security, and demonstrate compliance
    `,
  },
  // {
  //   image: cloud,
  //   title: "Cloud Monitoring",
  //   description: `
  //   To support hybrid environments, the SolarWinds Platform can retrieve data from the Amazon Web
  //   Services (AWS) EC2 and Microsoft Azure cloud service platforms to track availability, performance, 
  //   applications, and more for instances and VMs.
  //   `,
  // },
  {
    image: web,
    title: "Website",
    description: `
    SolarWinds monitors web application availability and performance in real-time. For optimal
    end-user performance, SolarWinds Observability allows you to monitor all critical website 
    performance metrics, down to the individual webpage. To help ensure your website is running
    quickly and smoothly, alerts and data visualization provide a granular view into your web performance.
    `,
  }
];

export const Accordion = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <>
      {/* <h2 style={{ textAlign: "center", marginBottom: "40px" }}>
        Features of BigFix{" "}
      </h2> */}
      <div className="summary-module-container">
        <div
          className="modules"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          {modules.map(({ image, title, description, description1 }, index) => (
            <div key={index}>
              <Module
                image={image}
                title={title}
                description={description}
                description1={description1}
              />
            </div>
          ))}
        </div>

        <div
          className="modules"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          data-aos-duration="600"
        >
          {modules1.map(
            ({ image, title, description, description1 }, index) => (
              <div key={index}>
                <Module
                  image={image}
                  title={title}
                  description={description}
                  description1={description1}
                />
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};
