import React, { useEffect, useState } from "react";
import { NavBar } from "../component/NavBar";
import { Container } from "react-bootstrap";
import { Tenable } from "../component/Tenable/Tenable.js";
import { Footer } from "../component/Footer";
import { Loader } from "../component/loader.js";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

export const TeanablePage = () => {
  const [loading, setLoading] = useState(true);

  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>
              Tenable - Cybersecurity Vulnerability & Cloud Management Software
              | Techsa Services Pvt. Ltd.
            </title>
            <meta
              name="description"
              content="Tenable offers cybersecurity solutions including vulnerability management, continuous monitoring, risk assessment to proactively identify and mitigate cyber threats."
            />
             <link href="/Tenable" />
             <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <Container>
            <div className="tenable">
              <Tenable />
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};
