import React, { useEffect, useState } from 'react'
import { Summary } from './Summary'
import { AssetManagement } from './AssetManagement'
import { IoTSecurity } from './IoTSecurity'
import { DeviceSecurity } from './DeviceSecurity'
import { Remediation } from './Remediation'
import { FaLongArrowAltRight } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import { ContactModal } from '../ContactModal'

export const Armis = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [showContactModal, setShowContactModal] = useState(false);

  const handleRequestDemo = () => {
    setShowContactModal(true);
  };

  const handleCloseContactModal = () => {
    setShowContactModal(false);
  };

  return (
    <>
    <div>
    <h1 style={{ textAlign: "center" }}>ARMIS</h1>
     <Summary />
     <h2 style={{textAlign:'center'}}>Featured Solutions</h2>
     <div style={{ margin:"40px 0 40px 0" }}>
     <AssetManagement />
     </div>
     <div style={{ margin:"40px 0 40px 0" }}>
     <IoTSecurity />
     </div>
     <div style={{ margin:"40px 0 40px 0" }}>
     <DeviceSecurity  />
     </div>
     <div style={{ margin:"40px 0 40px 0" }}>
     <Remediation  />
     </div>
     <div style={{ textAlign: 'center', marginTop: '20px' }}>
     <button className="custom-button" onClick={handleRequestDemo}>
          <span>
          Request Details <FaLongArrowAltRight />
          </span>
        </button>
      </div>

      <ContactModal style={{zIndex:"100090"}} showModal={showContactModal} onClose={handleCloseContactModal} />
    </div>
  </>
  )
}
