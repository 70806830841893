import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export const Compliance = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="summary-module-container">
      <div
        className="summary"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        data-aos-duration="600"
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center" }}>
          {" "}
          BigFix Compliance
        </h2>
        <p style={{ textAlign: "center" }}>
          BigFix Compliance continuously enforces configuration compliance with
          thousands of out-of-the-box security checks aligned with
          industry-standard security benchmarks. Plus, it delivers advanced
          vulnerability posture reporting for remediation prioritization.
        </p>
      </div>
    </div>
  );
};
