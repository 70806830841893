import React from "react";
import img1 from "../../assets/img/136048.png";

export const Summary = () => {
  return (
    <div className="big">
      <div>
        <img src={img1} alt="" />
      </div>
      <div>
        <p>
          BigFix is an endpoint management platform that enables organizations
          to efficiently manage and secure endpoints across their network. It
          provides real-time visibility, patch management, security compliance,
          and automated remediation, allowing IT teams to control and optimize
          endpoint configurations, ensuring a secure and compliant computing
          environment.
        </p>
      </div>
    </div>
  );
};
