import React, { useState, useEffect } from "react";
import { NavBar } from "../component/NavBar";
import { Container } from "react-bootstrap";
import Bigfixs from "../component/bigfix/bigfix";
import { Footer } from "../component/Footer";
import { Loader } from "../component/loader";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const Bigfix = () => {
  const [loading, setLoading] = useState(true);

  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>
              BigFix - Security Compliance Patch Management Software & Inventory
              Automation | Techsa Services Pvt. Ltd.
            </title>
            <meta
              name="description"
              content="BigFix provides real-time visibility, patch management, security compliance, and automated remediation, allowing IT teams to control and optimize endpoints."
            />
            <link href="/Bigfix" />
            <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <Container>
            <div className="bigfix">
              <Bigfixs />
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Bigfix;
