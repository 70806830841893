import React, { useEffect, useState } from "react";
import img1 from "../../assets/img/sw-removebg-preview.png";



const ImageWithText = ({ onClick }) => {
  const [clickedArea, setClickedArea] = useState("");
  const [steps, setSteps] = useState([]);
  const [run, setRun] = useState(false);

  const joyrideSteps = [
    {
      
      target: ".ImageWithTexts",
      content: "Click on different areas to explore!",
    },
  ];

  useEffect(() => {
    if (run) {
      setSteps(joyrideSteps);
    }
    setRun(true);
  }, [run]);

  const handleImageClick = (event) => {
    const { offsetX, offsetY } = event.nativeEvent;
    console.log("Clicked at:", offsetX, offsetY);
    if (window.innerWidth <= 768) {
      const areas = {
        Database: { coords: [182, 104, 367, 134], name: "Database" },
        Network: { coords: [182, 190, 367, 226], name: "Network" },
        Infrastructure: { coords: [182, 58, 367, 86], name: "Infrastructure" },
        Application: { coords: [182, 4, 367, 44], name: "Application" },
        Cloud: { coords: [182, 146, 367, 175], name: "Cloud Services" },
        Solarwinds: { coords: [227, 64, 327, 174], name: "Solarwinds" },
        Automate: { coords: [220, 7, 288, 32], name: "Automate" },
        Observe: { coords: [333, 45, 383, 75], name: "Observe" },
        Remediate: { coords: [355, 151, 373, 189], name: "Remediate" },
        Visualize: { coords: [256, 209, 310, 227], name: "Visualize" },
      };
      const clickedAreaName = Object.keys(areas).find((area) => {
        const [x1, y1, x2, y2] = areas[area].coords;
        return offsetX >= x1 && offsetX <= x2 && offsetY >= y1 && offsetY <= y2;
      });

      setClickedArea(clickedAreaName || "");
      onClick(clickedAreaName);
      console.log("Clicked Area:", clickedAreaName);
    } else {
      const areas = {
        Database: { coords: [235, 133, 453, 187], name: "Database" },
        Network: { coords: [236, 258, 455, 312], name: "Network" },
        Infrastructure: { coords: [272, 83, 538, 149], name: "Infrastructure" },
        Application: { coords: [272, 6, 538, 76], name: "Application" },
        Cloud: { coords: [239, 197, 458, 249], name: "Cloud Services" },
        Solarwinds: { coords: [305, 85, 478, 248], name: "Solarwinds" },
        Automate: { coords: [59, 162, 129, 173], name: "Automate" },
        Observe: { coords: [65, 183, 126, 192], name: "Observe" },
        Remediate: { coords: [55, 219, 134, 229], name: "Remediate" },
        Visualize: { coords: [61, 202, 127, 209], name: "Visualize" },
      };
      const clickedAreaName = Object.keys(areas).find((area) => {
        const [x1, y1, x2, y2] = areas[area].coords;
        return offsetX >= x1 && offsetX <= x2 && offsetY >= y1 && offsetY <= y2;
      });

      setClickedArea(clickedAreaName || "");
      onClick(clickedAreaName);
      console.log("Clicked Area:", clickedAreaName);
    }
  };

  return (
    <>
      <div>
        <img
          src={img1}
          alt=""
          onClick={handleImageClick}
          className="ImageWithTexts"
        />
      </div>
    </>
  );
};

export default ImageWithText;
