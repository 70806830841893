import React from 'react'
import {Testimonials} from "../Testimonials/Testimonial"

export const Testimonial = () => {
  return (
    <>
    <h2 style={{textAlign:'center',color:'red', marginTop:"50px"}}>Testimonial</h2>
          <div>
            <Testimonials />
          </div>
    </>
    
  )
}
