import React, { useEffect, useState } from "react";
import { Loader } from "../component/loader";
import { NavBar } from "../component/NavBar";
import { Container } from "react-bootstrap";
import { Footer } from "../component/Footer";
import { Solarwinds } from "../component/SolarWinds/Solarwinds";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";

const SolarwindsPage = () => {
  const [loading, setLoading] = useState(true);

  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>
              SolarWinds - IT Infrastructure & Database Monitoring Software,
              Network Traffic Analysis Tools - Techsa Services Pvt. Ltd.
            </title>
            <meta
              name="description"
              content="Unify IT monitoring and management with SolarWinds. Optimize network performance, security, applications for the hybrid IT infrastructure. Request details!"
            />
              <link href="/SolarWinds" />
             <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <Container>
            <div className="solarwinds">
              <Solarwinds />
            </div>
          </Container>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SolarwindsPage;
