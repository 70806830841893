import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavBar } from "../component/NavBar";
import { Footer } from "../component/Footer";
import { Loader } from "../component/loader";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

export const Career = () => {
  const [loading, setLoading] = useState(true);
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [buttonText, setButtonText] = useState("Send");
  const [selectedOption, setSelectedOption] = useState("");

  ReactGA.initialize("G-6WD5GM86HD");
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formDetails.firstName ||
      !formDetails.lastName ||
      !formDetails.email ||
      !selectedOption
    ) {
      toast.error("Please Fill all the feild");
      return;
    }

    setButtonText("Sending...");

    try {
      await pushMessage(
        `${formDetails.firstName} ${formDetails.lastName}`,
        formDetails.email,
        formDetails.message,
        formDetails.phone,
        selectedOption
      );

      setButtonText("Send");
      setFormDetails({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
      setSelectedOption("");
      toast.success("Delivered");
    } catch (error) {
      console.error(error);
      toast.error("An error occured. Try again later.");
    } finally {
      setButtonText("Send");
    }
  };

  const pushMessage = async (
    name,
    email,
    message,
    phone = "000",
    selectedOption
  ) => {
    const messageType = "contact";
    const messageBody = {
      title: `${messageType} Form (Website) - ${name}`,
      body: `Name: ${name}\nMessage: ${message}\nEmail: ${email}\nPhone: ${phone}\nRole: ${selectedOption}`,
      type: "note",
    };

    const pushbulletResponse = await fetch(
      "/push.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Connection: "keep-alive",
          "cache-control": "no-cache",
        },
        body: JSON.stringify(messageBody),
      }
    );

    if (!pushbulletResponse.ok) {
      throw new Error("API request failed");
    }
  };

  const downloadPdf = (pdfPath) => {
    const anchor = document.createElement("a");
    anchor.href = pdfPath;
    anchor.download = "Requirement.pdf";

    document.body.appendChild(anchor);
    anchor.click();

    document.body.removeChild(anchor);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <Helmet>
            <title>Career Opportunities - Techsa Services Pvt. Ltd.</title>
            <meta
              name="description"
              content="Start your IT career at Techsa Services Pvt. Ltd.! We're seeking talented Network Engineers, Security Engineers, and Business Development Managers."
            />
            <link href="/Career" />
            <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Techsa Services Pvt. Ltd.",
            "url": "https://www.techsa.net/",
            "logo": "",
            "sameAs": "https://in.linkedin.com/company/techsa-services-pvt-ltd"
          }
        `}
      </script>
          </Helmet>
          <NavBar />
          <section className="contact" id="connect">
            <>
              <Row className="align-items-center">
                <h1 style={{ textAlign: "center", color: "red" }}>
                  Careers <span style={{ color: "#476930" }}>@ Techsa</span>
                </h1>
                <Col md={12}>
                  <div className="opportunities-container">
                    <div className="opportunities-header">
                      <h3>Available Opportunities</h3>
                      <span>with requirements</span>
                    </div>
                    <div className="opportunities-list">
                      <div className="opportunity-item">
                        <h3>Observability Engineer</h3>
                        <p>
                          Engineering graduates with understanding of IT
                          networks and knowledge of Networking. Concepts Desire
                          to implement software solutions and Good Communication
                          skills
                        </p>
                        <button
                          className="custom-button"
                          onClick={() =>
                            downloadPdf("/Observability_Engineer_JD_Techsa.pdf")
                          }
                          type="submit"
                        >
                          <span>Read More</span>
                        </button>
                      </div>
                      <div className="opportunity-item">
                        <h3>Security Engineer</h3>
                        <p>
                          Engineering graduates with understanding of Operating
                          Systems and knowledge of Security Concepts. Desire to
                          implement software solutions and Good Communication
                          skills
                        </p>
                        <button
                          className="custom-button"
                          onClick={() =>
                            downloadPdf("/Security_Engineer_JD_Techsa.pdf")
                          }
                          type="submit"
                        >
                          <span>Read More</span>
                        </button>
                      </div>
                      <div className="opportunity-item">
                        <h3>Business Development Manager</h3>
                        <p>
                          Engineering graduates with keenness to pursue IT Sales
                          Careers Corporate Sales of IT Solutions & Services
                          Presentable with good Communication and articulation
                          skills
                        </p>
                        <button
                          className="custom-button"
                          onClick={() =>
                            downloadPdf("/Business_Development_JD_Techsa.pdf")
                          }
                          type="submit"
                        >
                          <span>Read More</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col md={6} className="mx-auto">
                  <h2
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginBottom: "30px",
                    }}
                  >
                    Fill the details below
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.firstName}
                          placeholder="First Name"
                          onChange={(e) =>
                            onFormUpdate("firstName", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.lastName}
                          placeholder="Last Name"
                          onChange={(e) =>
                            onFormUpdate("lastName", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          value={formDetails.email}
                          placeholder="Email"
                          onChange={(e) =>
                            onFormUpdate("email", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <input
                          type="text"
                          maxLength={10}
                          value={formDetails.phone}
                          placeholder="Mobile No."
                          onChange={(e) =>
                            onFormUpdate("phone", e.target.value)
                          }
                        />
                      </Col>
                      <Col sm={6} className="px-1">
                        <select
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.target.value)}
                          style={{ borderRadius: "20px" }}
                        >
                          <option value="" selected disabled>
                            Career Options
                          </option>
                          <option value="Network Engineer">
                            Network Engineer
                          </option>
                          <option value="Security Engineer">
                            Security Engineer
                          </option>
                          <option value="Business Development Manager">
                            Business Development Manager
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <Col sm={6} className="px-1">
                      <button type="submit">
                        <span>{buttonText}</span>{" "}
                      </button>
                    </Col>
                  </form>
                </Col>
              </Row>
            </>
          </section>
          <Footer />
        </div>
      )}
    </>
  );
};
