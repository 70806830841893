import React from "react";
import img1 from "../../assets/img/headerLogoLight.webp";

export const Summary = () => {
  return (
    <div className="fresh">
      <div>
        <img src={img1} alt="" />
      </div>
      <div>
        <p>
          Freshworks is a customer engagement software company offering a suite
          of products, including customer support, marketing, and sales
          solutions. Designed for businesses of all sizes, Freshworks provides
          tools for improved customer communication, collaboration, and
          automation. The user-friendly platform aims to enhance customer
          experiences and streamline operational processes.
        </p>
      </div>
    </div>
  );
};
